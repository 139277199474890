@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/media-queries.less';

.featuredCategories {
  @media screen and (min-width: @screen-lg) {
    max-width: 1504px;
    margin-left: auto;
    margin-right: auto;
  }

  .featuredContainer {
    @media screen and (min-width: @screen-lg) {
      margin-bottom: -@spacer10;
    }
  }

  .categoryLinks {
    @media screen and (max-width: @screen-md-max) {
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      white-space: nowrap;
      &::-webkit-scrollbar-track {
        margin: @spacer6;
      }
    }
    @media screen and (min-width: @screen-lg) {
      display: flex;
      flex-wrap: wrap;
    }
    .categoryLink {
      flex-shrink: 0;
      margin-right: @spacer4;
      width: 280px;
      @media screen and (max-width: @screen-md-max) {
        display: inline-block;
        &:first-child {
          margin-left: @spacer6;
        }
        &:last-child {
          margin-right: @spacer6;
        }
      }
      @media screen and (min-width: @screen-lg) {
        margin-right: @spacer6;
        flex-basis: calc(33.3% - 1.5rem); //1.5rem because 3 each row, but only 2 with margin of 2rem 3/2=1.5
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      .categoryImage {
        aspect-ratio: 1 / 1;
        background-color: @Gray10;
        picture {
          aspect-ratio: inherit;
        }
        img {
          width: 100%;
          aspect-ratio: inherit;
          object-fit: cover;
        }
      }
    }
  }
}
